import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule, PublicAPI, APIConstant } from "@hr-core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import {
    AppCommonModule, AuthInterceptor, ResponseInterceptor, AppConfigModule
} from "projects/common/src/public-api";
import { ValidateComponent } from "./validate/validate.component";
import { NgxCsvParserModule } from 'ngx-csv-parser';


@NgModule({
    declarations: [AppComponent, ValidateComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SweetAlert2Module.forRoot(),
        AppCommonModule,
        CoreModule,
        NgxCsvParserModule,
        AppConfigModule.forRoot({
            publicAPIs: PublicAPI,
            apiPath: APIConstant.basePath
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
